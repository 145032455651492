<template>
  <div>
    <h4 class="link-exchange-title">
      {{ $t("MY_PROFILE.LINKED_EXCHANGE") }}
    </h4>
    <div class="exchange-container" v-if="exchange">
      <div class="d-flex align-items-center flex-wrap">
        <div class="d-flex align-items-center mr-5">
          <div class="exchange-icon">
            <img v-if="exchange.exchange_logo" :src="exchange.exchange_logo" alt="" />
          </div>
          <div>
            <h5>{{ exchange.exchange }}</h5>
            <!-- <h4>{{ exchange.name }}</h4> -->
          </div>
        </div>
        <!-- <h3 class="price">
        993,31
        <span> $ </span>
      </h3> -->
      </div>
      <div>
        <!-- <Button
        color="light-dark"
        customClass="mr-2 edit-btn"
        @click="openEditExhangeModal"
      >
        {{ $t("GLOBAL_EDIT") }}
      </Button> -->
        <Button color="light-danger" @click="openRemoveExhangeModal">
          {{ $t("GLOBAL_REMOVE") }}
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import { getDateFormat } from "@/helpers";
export default {
  props: {
    exchange: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      getDateFormat,
    };
  },
  methods: {
    openEditExhangeModal() {
      this.$emit("openEditExhangeModal");
    },
    openRemoveExhangeModal() {
      this.$emit("openRemoveExhangeModal", this.exchange);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index";
</style>
