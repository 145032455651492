<template>
  <div class="account-activity-container row no-gutters" v-if="log">
    <div class="col-lg-6">
      <h5>
        {{ log.location.device_type }}
        <span>
          -
          {{ log.location.browser_name }}
        </span>
      </h5>
      <h6>
        {{ log.ip_address }}
      </h6>
    </div>
    <div class="col-lg-6">
      <h5>
        {{ log.location.country }}
        <span>
          -
          {{ log.location.city }}
        </span>
      </h5>
      <h6>
        {{ $t("MY_PROFILE.LOGGED_IN") }}
        <span>
          {{ getDateFormat(log.login_at, "MM-DD-YYYY hh:mm A") }}
        </span>
      </h6>
    </div>
  </div>
</template>

<script>
import { getDateFormat } from "@/helpers";
export default {
  props: {
    log: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      getDateFormat,
    };
  },
  methods: {
    openDisabledAccountModal() {
      this.$emit("openDisabledAccountModal");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index";
</style>
