<template>
  <div>
    <b-modal
      id="add-phone-modal"
      hide-footer
      @hide="closeModal"
      centered
      size="lg"
      content-class="custom-modal-container"
    >
      <template v-slot:modal-header="{ close }">
        <b-icon class="close-modal-icon" icon="x" @click="close"></b-icon>
      </template>
      <div :class="$i18n.locale == 'en' ? '' : 'rtl'">
        <h6 class="modal-top-title">
          {{
            selectedUser && selectedUser.phone
              ? $t("MY_PROFILE.CHNAGE_PHONE")
              : $t("MY_PROFILE.ADD_PHONE")
          }}
        </h6>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
          <b-form @submit.prevent="handleSubmit(onSubmit)" a-tag="formAddPhone">
            <div class="row">
              <b-form-group class="col-lg-12">
                <TextField
                  v-model="form.phone"
                  rules="required|numeric"
                  :label="
                    selectedUser && selectedUser.phone
                      ? $t('MY_PROFILE.NEW_PHONE')
                      : $t('MY_PROFILE.PHONE_NUMBER')
                  "
                  :name="$t('GLOBAL_PHONE')"
                  :placeholder="
                    selectedUser && selectedUser.phone
                      ? $t('MY_PROFILE.NEW_PHONE')
                      : $t('MY_PROFILE.PHONE_NUMBER')
                  "
                />
              </b-form-group>
              <b-form-group class="col-lg-12" v-if="otptype === 'phone'">
                <label for="">
                  {{ $t("MY_PROFILE.SMS_VERFICATION") }}
                </label>
                <b-input-group :prepend="$t('MY_PROFILE.SEND_CODE')" class="sms-verfication">
                  <TextField
                    class="w-100"
                    v-model="form.otp"
                    rules="required|numeric"
                    :name="$t('MY_PROFILE.SMS_VERFICATION')"
                    :placeholder="$t('MY_PROFILE.ENTER_SMS_VERFICATION')"
                  />
                </b-input-group>
              </b-form-group>
            </div>
            <b-form-group class="d-flex justify-content-end">
              <div class="d-inline-block mr-3">
                <Button
                  type="button"
                  color="light-dark"
                  customClass="text-uppercase"
                  @click="$bvModal.hide('add-phone-modal')"
                >
                  {{ $t("GLOBAL_CANCEL") }}
                </Button>
              </div>
              <Button
                type="submit"
                color="primary"
                :loading="loading"
                customClass="text-uppercase w-134"
              >
                {{ $t("GLOBAL_SUBMIT") }}
              </Button>
            </b-form-group>
          </b-form>
        </ValidationObserver>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "MY_PROFILE.ADD_PHONE",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    selectedUser: {
      type: Object,
      default: () => {},
    },
    otptype: {
      type: String,
      default: "",
    },
  },

  watch: {
    selectedUser: {
      immediate: true,
      handler(val) {
        if (!val) return;
        this.form = {
          phone: val.phone,
        };
      },
    },
  },
  data() {
    return {
      form: {
        phone: null,
        otp: null,
      },
    };
  },
  methods: {
    closeModal() {
      setTimeout(() => {
        this.$emit("closeModal");
        this.$refs.observer.reset();
        this.form = {
          phone: null,
          otp: null,
        };
      }, 200);
    },
    onSubmit() {
      this.$emit("handleAddOrChangePhone", this.form);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index";
</style>
