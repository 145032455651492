<template>
  <div class="media-container">
    <b-media tag="li">
      <template #aside>
        <img class="card-img" src="@/assets/images/leader-account.svg" alt="" />
      </template>
      <div>
        <h5 class="card-title">
          {{ $t("LEADERS.LEADER_ACCOUNT") }}

          <span v-if="leader.status === 'active'">
            {{ $t("ON") }}
          </span>
        </h5>
        <p class="card-desc">
          <span v-if="leader.status === 'active'">
            {{ $t("LEADERS.ACCOUNT_ACTIVE") }}
          </span>
          <span v-else>
            {{ $t("LEADERS.ACCOUNT_INACTIVE") }}
          </span>
        </p>
        <div class="card-actions" v-if="leader.status === 'active'">
          <p class="card-date">
            <img class="card-date-img" src="@/assets/images/calendar.svg" alt="" />
            {{ $t("LEADERS.MEMBER_SINCE") }}
            {{ leader.readable_created_at }}
          </p>
          <span class="free-subscription">
            <img class="card-date-img" src="@/assets/images/subscriptions.svg" alt="" />
            {{
              leader.following_fee === "paid"
                ? $t("LEADERS.PAID_SUBSCRIPTION")
                : $t("LEADERS.FREE_SUBSCRIPTION")
            }}
          </span>
        </div>

        <!-- timeline -->
        <div
          class="timeline-container"
          v-if="leader.status !== 'active' && leader.status !== 'inactive'"
        >
          <div
            class="timeline-body sent"
            v-for="(timeline, index) in timelines"
            :key="index"
            :class="timeline.status"
          >
            <h4>
              {{ timeline.name }}
            </h4>

            <h5>
              {{ timeline.date }}
            </h5>
            <p>
              {{ timeline.desc }}
            </p>
          </div>
        </div>
      </div>
    </b-media>

    <div class="card-manage" v-if="leader.status === 'active' || leader.status === 'inactive'">
      <div class="d-inline-block mr-3" v-if="leader.status === 'active'">
        <Button color="light-danger" @click="handleActiveOrDeactive('inactive')">
          {{ $t("DEACTIVATE") }}
        </Button>
      </div>
      <div class="d-inline-block mr-3" v-else>
        <Button @click="handleActiveOrDeactive('active')">
          {{ $t("GLOBAL_ACTIVE") }}
        </Button>
      </div>
      <Button @click="handleManageAccount">
        {{ $t("MANAGE") }}
      </Button>
    </div>

    <!-- actions for timelines -->
    <div class="card-manage" v-if="leader.status === 'pending' || leader.status === 'approved'">
      <div class="d-inline-block mr-3" v-if="leader.status === 'pending'">
        <Button color="light-danger" @click="handleActiveOrDeactive('canceled')">
          {{ $t("LEADERS.CANCEL_REQUEST") }}
        </Button>
      </div>
      <div class="d-inline-block mr-3" v-else>
        <Button @click="handleActiveOrDeactive('active')">
          {{ $t("LEADERS.PROCEED") }}
        </Button>
      </div>
    </div>
  </div>
</template>
<script>
import { getDateFormat } from "@/helpers";

export default {
  props: {
    leader: {
      type: Object,
      default: () => {},
    },
    leaderActivities: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    timelines() {
      const activities = this.leaderActivities.map((activity) => {
        return {
          name: this.$t(activity.properties?.attributes?.status?.toUpperCase()),
          date: getDateFormat(activity.updated_at, "MM-DD-YYYY hh:mm A"),
          desc: activity.message,
          status: activity.properties?.attributes?.status,
        };
      });
      if (activities.length) {
        activities.unshift({
          name: this.$t("SENT"),
          date: activities[0]?.date,
          desc: this.$t("LEADERS.YOUR_REQUEST"),
          status: "sent",
        });
      }
      return activities;
    },
  },

  methods: {
    handleActiveOrDeactive(status) {
      this.$emit("handleActiveOrDeactive", status);
    },
    handleManageAccount() {
      this.$emit("handleManageAccount");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index";
</style>
