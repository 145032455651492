<template>
  <div v-if="user" class="profile-container">
    <div class="profile-image">
      <image-uploader
        :defaultImage="user.image"
        :maxSize="2048"
        :name="$t('MY_PROFILE.IMAGE')"
        @image-upload="handleImageUpload"
      />
    </div>
    <div>
      <h5>
        {{ user.name }}
        <span v-if="user.email_verified_at">
          <b-icon icon="check-circle-fill"></b-icon>
          {{ $t("MY_PROFILE.VERIFICATION") }}
        </span>
      </h5>
      <h6>{{ user.email }}</h6>
      <div v-if="user.is_active_leader">
        <span class="leader">
          <img src="@/assets/images/leader-icon.svg" alt="" />
          {{ $t("LEADERS.LEADER") }}
        </span>
        <span class="follower">
          <img src="@/assets/images/users.svg" alt="" />
          {{ user.leader.data.followers_count }} {{ $t("LEADERS.FOLLOWERS") }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import ImageUploader from "@/components/Shared/ImageUploader";

export default {
  components: {
    ImageUploader,
  },
  props: {
    user: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    handleImageUpload(file) {
      this.$emit("handleImageUpload", file);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index";
</style>
