<template>
  <div class="media-container">
    <b-media tag="li">
      <template #aside>
        <img class="card-img" :src="imageName" alt="" />
      </template>
      <div>
        <h5 class="card-title">
          {{ title }}

          <!-- <span v-if="value">
            {{ handleAddAsterisks(value) }}
          </span> -->
        </h5>
        <p class="card-desc" v-if="description">
          {{ description }}
        </p>
        <p class="card-desc" v-if="value">
          {{ handleAddAsterisks(value) }}
        </p>
      </div>
    </b-media>
    <div class="card-actions">
      <div class="d-inline-block mr-2">
        <Button
          v-if="showRemove"
          customClass="card-btn "
          color="danger"
          @click="handleOpenRemoveModal"
        >
          {{ $t("GLOBAL_REMOVE") }}
        </Button>
      </div>
      <Button v-if="showChange" customClass="card-btn" @click="handleOpenChangeModal">
        {{ $t("GLOBAL_CHANGE") }}
      </Button>
      <Button v-if="showAdd" customClass="card-btn" @click="handleOpenAddModal">
        {{ $t("GLOBAL_ADD") }}
      </Button>
    </div>
  </div>
</template>

<script>
import { handleAddAsterisks } from "@/helpers";
export default {
  props: {
    imageName: {
      type: String,
      required: true,
    },
    showChange: {
      type: Boolean,
      default: true,
    },
    showRemove: {
      type: Boolean,
      default: false,
    },
    showAdd: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      handleAddAsterisks,
    };
  },
  methods: {
    handleOpenRemoveModal() {
      this.$emit("handleOpenRemoveModal");
    },

    handleOpenAddModal() {
      this.$emit("handleOpenAddModal");
    },

    handleOpenChangeModal() {
      this.$emit("handleOpenChangeModal");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index";
</style>
