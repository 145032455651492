<template>
  <div v-if="user">
    <div class="row mb-4">
      <div class="col-xl-5">
        <div class="main-card profile-card">
          <my-profile-info
            :user="user"
            @handleImageUpload="handleImageUpload"
          />
        </div>
      </div>
      <div class="col-xl-7">
        <!-- account activity -->
        <div class="main-card account-activity">
          <h4 class="account-title">
            {{ $t("MY_PROFILE.BROWSER_SESSIONS") }}
          </h4>

          <div class="account-activity-body">
            <account-acitvity
              v-for="(log, index) in user.authentications"
              :key="index"
              :log="log"
              @openDisabledAccountModal="openDisabledAccountModal"
            />
          </div>
        </div>
      </div>
    </div>
    <refer-profile />

    <!-- General settings -->
    <div class="main-card general-container">
      <h4 class="profile-title">
        {{ $t("GENERAL_SETTINGS") }}
      </h4>

      <div class="row">
        <div class="col-md-12 col-xl-6">
          <profile-card
            a-tag="userNameCard"
            :imageName="require('@/assets/images/card.svg')"
            :title="user.name"
            :description="$t('MY_PROFILE.UPDATE_USER_NAME')"
            @handleOpenChangeModal="handleOpenModal('change-username-modal')"
          />
        </div>
        <div class="col-md-12 col-xl-6">
          <profile-card
            a-tag="phoneCard"
            :imageName="require('@/assets/images/phone.svg')"
            :title="$t('GLOBAL_PHONE')"
            :value="user.phone"
            :showAdd="!user.phone"
            :showRemove="!!user.phone"
            :showChange="!!user.phone"
            @handleOpenAddModal="handleOpenModal('add-phone-modal')"
            @handleOpenChangeModal="handleOpenModal('add-phone-modal')"
            @handleOpenRemoveModal="handleOpenRemoveModal"
          />
        </div>
        <div class="col-md-12 col-xl-6">
          <profile-card
            a-tag="updatePasswordCard"
            :imageName="require('@/assets/images/password.svg')"
            :title="$t('LOGIN_PASSWORD')"
            :description="$t('MY_PROFILE.UPDATE_PASSWORD')"
            @handleOpenChangeModal="handleOpenModal('change-password-modal')"
          />
        </div>
        <div class="col-md-12 col-xl-6">
          <profile-card
            a-tag="userEmailCard"
            :imageName="require('@/assets/images/email.svg')"
            :title="$t('LOGIN_EMAIL')"
            :value="user.email"
            @handleOpenChangeModal="handleOpenModal('change-email-modal')"
          />
        </div>
      </div>
    </div>

    <!-- Leader account -->
    <div class="main-card" v-if="user.is_leader">
      <leader-account
        :leader="user.leader.data"
        :leaderActivities="leaderActivities"
        @handleActiveOrDeactive="handleActiveOrDeactive"
        @handleManageAccount="handleOpenModal('leader-settings-modal')"
      />
    </div>

    <!-- Exchanges  -->
    <div class="main-card" v-if="user.exchanges.data.length">
      <leader-exchange
        v-for="(exchange, index) in user.exchanges.data"
        :key="index"
        :exchange="exchange"
        @openRemoveExhangeModal="openRemoveExhangeModal"
      />
    </div>

    <!-- Delete account  -->
    <div class="main-card">
      <delete-account @handleDeleteAccount="handleOpenDeleteAccountModal" />
    </div>

    <user-name-modal
      :loading="changeUserLoading"
      :selectedUser="selectedUser"
      id="change-username-modal"
      @handleChange="handleChangeUserName"
      @closeModal="handleCloseModal"
    />

    <add-phone-modal
      :loading="addPhoneLoading"
      :selectedUser="selectedUser"
      :otptype="selectedOtptype"
      @closeModal="handleCloseModal"
      @handleAddOrChangePhone="handleAddOrChangePhone"
    />

    <change-password-modal
      :loading="passwordLoading"
      :selectedUser="selectedUser"
      @closeModal="handleCloseModal"
      @handleChangePassword="handleChangePassword"
      @handleOpenForgotPasswordModal="handleOpenForgotPasswordModal"
    />
    <forgot-password-modal
      :loading="passwordLoading"
      @handleForgotPassword="handleForgotPassword"
    />
    <change-email-modal
      :loading="emailLoading"
      :selectedUser="selectedUser"
      :otptype="selectedOtptype"
      @handleChangeEmail="handleChangeEmail"
      @closeModal="handleCloseModal"
    />

    <!-- Manage leader  -->
    <leader-settings-modal
      :leader="selectedUser && selectedUser.leader && selectedUser.leader.data"
      :loading="leaderLoading"
      @handleUpdateLeaderSettings="handleUpdateLeaderSettings"
      @closeModal="handleCloseModal"
    />
    <!-- Toast confirmation modal -->
    <toast-confirmation-modal
      class="toast-confirmation"
      :title="toastConfirmation.title"
      :message="toastConfirmation.message"
      :subMessage="toastConfirmation.subMessage"
      :imageName="toastConfirmation.imageName"
      :subMessageClass="toastConfirmation.subMessageClass"
      :showCancelButton="toastConfirmation.showCancelButton"
      :showConfirmButton="toastConfirmation.showConfirmButton"
      :showCloseButton="toastConfirmation.showCloseButton"
      :confirmColor="toastConfirmation.confirmColor"
      :isConfirmationLoading="toastConfirmation.isConfirmationLoading"
      @onConfirm="onConfirm"
      @onCancel="resetConfirmationModal"
      @onClose="onClose"
      @onHideConfirmation="resetConfirmationModal"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { handleAddAsterisks } from "@/helpers";
import MyProfileInfo from "@/components/Application/MyProfile/MyProfileInfo";
import ProfileCard from "@/components/Application/MyProfile/ProfileCard";
import UserNameModal from "@/components/Application/MyProfile/UserNameModal";
import AddPhoneModal from "@/components/Application/MyProfile/AddPhoneModal";
import ChangePasswordModal from "@/components/Application/MyProfile/ChangePasswordModal";
import ForgotPasswordModal from "@/components/Application/MyProfile/ForgotPasswordModal";
import ChangeEmailModal from "@/components/Application/MyProfile/ChangeEmailModal";
import DeleteAccount from "@/components/Application/MyProfile/DeleteAccount";
import ReferProfile from "@/components/Application/MyProfile/ReferProfile";
import AccountAcitvity from "@/components/Application/MyProfile/AccountAcitvity";
import LeaderAccount from "@/components/Application/MyProfile/LeaderAccount";
import LeaderSettingsModal from "@/components/Application/MyProfile/LeaderSettingsModal";
import LeaderExchange from "@/components/Application/Leaders/LeaderExchange";
import {
  patchUserRequest,
  patchChangePasswordRequest,
  postUploadImageRequest,
  deleteAccountRequest,
  postForgotPasswordRequest,
} from "@/api/register";
import {
  deleteExchangeRequest,
  getOpenOrdersRequest,
  patchLeaderRequest,
  getLeaderActivitiesRequest,
} from "@/api/leaders";

export default {
  components: {
    MyProfileInfo,
    ProfileCard,
    UserNameModal,
    AddPhoneModal,
    ChangePasswordModal,
    ForgotPasswordModal,
    ChangeEmailModal,
    ReferProfile,
    AccountAcitvity,
    LeaderAccount,
    LeaderSettingsModal,
    LeaderExchange,
    DeleteAccount,
  },
  computed: {
    ...mapGetters(["user", "token"]),
  },
  data() {
    return {
      handleAddAsterisks,
      changeUserLoading: false,
      addPhoneLoading: false,
      passwordLoading: false,
      emailLoading: false,
      modalName: null,
      selectedUser: null,
      selectedOtptype: null,
      selectedFormValue: null,
      selectedExchange: null,
      toastConfirmation: {
        title: "",
        message: "",
        name: "",
        subMessage: "",
        imageName: "success",
        subMessageClass: "",
        showCancelButton: false,
        showConfirmButton: false,
        showCloseButton: true,
        confirmColor: "danger",
      },
      openOrders: [],
      selectedStatus: null,
      leaderLoading: false,
      leaderActivities: [],
    };
  },
  watch: {
    user: {
      immediate: true,
      handler() {
        this.selectedUser = this.user;
        if (this.user.is_leader) {
          // load activities in case not active or inactive leader
          if (
            this.user.leader.data.status !== "active" &&
            this.user.leader.data.status !== "inactive"
          ) {
            this.handleGetLeaderActivities();
          }
          if (!this.openOrders.length) {
            this.handleLoadOpenOrders();
          }
        }
      },
    },
  },

  methods: {
    ...mapActions(["SetUser", "ShowToast", "Logout"]),
    handleOpenModal(modalName) {
      this.selectedUser = this.user;
      this.modalName = modalName;
      this.$bvModal.show(modalName);
    },

    // upload image
    handleImageUpload(file) {
      let formData = new FormData();
      formData.append("image", file.uploadedImage);
      this.ApiService(postUploadImageRequest(formData, this.user.id)).then(
        () => {
          this.handleSetUser("");
          setTimeout(() => {
            this.$bvModal.show("toast-confirmation-modal");
            this.toastConfirmation = {
              title: this.$t("MY_PROFILE.IMAGE_SUCCESSFULLY"),
              message: "",
              subMessage: "",
              imageName: "success",
              showCancelButton: false,
              showConfirmButton: false,
              showCloseButton: true,
              isConfirmationLoading: false,
            };
          }, 220);
        }
      );
    },

    // user name
    handleChangeUserName(formValue) {
      const body = {
        id: this.user.id,
        first_name: formValue.firstName,
        last_name: formValue.lastName,
        name: `${formValue.firstName} ${formValue.lastName}`,
      };
      this.changeUserLoading = true;
      this.ApiService(patchUserRequest(body))
        .then(() => {
          this.$bvModal.hide("change-username-modal");
          this.handleSetUser("");
          setTimeout(() => {
            this.$bvModal.show("toast-confirmation-modal");
            this.toastConfirmation = {
              title: this.$t("MY_PROFILE.CHNAGE_NAME_SUCCESSFULLY"),
              message: "",
              subMessage: "",
              imageName: "success",
              showCancelButton: false,
              showConfirmButton: false,
              showCloseButton: true,
              isConfirmationLoading: false,
            };
          }, 220);
        })
        .finally(() => {
          this.changeUserLoading = false;
        });
    },

    // phone
    handleAddOrChangePhone(formValue) {
      const body = {
        id: this.user.id,
        phone: formValue.phone,
        otp: formValue.otp || undefined,
      };
      this.selectedFormValue = formValue;
      this.addPhoneLoading = true;
      this.ApiService(patchUserRequest(body))
        .then(({ data }) => {
          if (data.data.is_otp_required) {
            this.selectedOtptype = "phone";
          } else {
            this.selectedFormValue = null;
            this.selectedOtptype = null;
            this.handleSetUser("");
            setTimeout(() => {
              this.$bvModal.show("toast-confirmation-modal");
              this.toastConfirmation = {
                title: this.user.phone
                  ? this.$t("MY_PROFILE.CHNAGE_PHONE_SUCCESSFULLY")
                  : this.$t("MY_PROFILE.ADD_PHONE_SUCCESSFULLY"),
                message: "",
                subMessage: "",
                imageName: "success",
                showCancelButton: false,
                showConfirmButton: false,
                showCloseButton: true,
                isConfirmationLoading: false,
              };
            }, 220);
            this.$bvModal.hide("add-phone-modal");
          }
        })
        .finally(() => {
          this.addPhoneLoading = false;
        });
    },

    handleOpenRemoveModal() {
      this.$bvModal.show("toast-confirmation-modal");
      this.toastConfirmation = {
        title: this.$t("MY_PROFILE.REMOVING_PHONE"),
        message: this.$t("MY_PROFILE.ARE_YOU_REMOVING_PHONE"),
        subMessage: this.handleAddAsterisks(this.user.phone, 5),
        name: "removePhone",
        imageName: "danger",
        showCancelButton: true,
        showConfirmButton: true,
        showCloseButton: false,
        isConfirmationLoading: false,
      };
    },

    // password
    handleChangePassword(formData) {
      const body = {
        id: this.user.id,
        current_password: formData.currentPassword,
        new_password: formData.password,
      };

      this.passwordLoading = true;
      this.ApiService(patchChangePasswordRequest(body))
        .then(() => {
          this.$bvModal.hide("change-password-modal");
          this.handleSetUser("");
          setTimeout(() => {
            this.$bvModal.show("toast-confirmation-modal");
            this.toastConfirmation = {
              title: this.$t("MY_PROFILE.CHNAGE_PASSWORD_SUCCESSFULLY"),
              message: "",
              subMessage: "",
              imageName: "success",
              showCancelButton: false,
              showConfirmButton: false,
              showCloseButton: true,
              isConfirmationLoading: false,
            };
          }, 220);
        })
        .finally(() => {
          this.passwordLoading = false;
        });
    },
    handleOpenForgotPasswordModal() {
      this.$bvModal.hide("change-password-modal");
      this.$bvModal.show("forgot-password-modal");
    },
    handleForgotPassword(formData) {
      const body = {
        ...formData,
      };
      this.passwordLoading = true;
      this.ApiService(postForgotPasswordRequest(body))
        .then(() => {
          setTimeout(() => {
            this.$bvModal.show("toast-confirmation-modal");
            this.toastConfirmation = {
              title: this.$t("LOGIN_SENT_EMAIL"),
              message: this.$t("LOGIN_TROUBLE"),
              subMessage: "",
              imageName: "success",
              showCancelButton: false,
              showConfirmButton: false,
              showCloseButton: true,
              isConfirmationLoading: false,
            };
          }, 220);
          this.$bvModal.hide("forgot-password-modal");
        })

        .finally(() => {
          this.passwordLoading = false;
        });
    },

    // email
    handleChangeEmail(formValue) {
      const body = {
        id: this.user.id,
        email: formValue.email,
        otp: formValue.otp || undefined,
      };
      this.selectedFormValue = formValue;

      this.emailLoading = true;
      this.ApiService(patchUserRequest(body))
        .then(({ data }) => {
          if (data.data.is_otp_required) {
            this.selectedOtptype = "email";
          } else {
            this.selectedFormValue = null;
            this.selectedOtptype = null;

            this.handleSetUser("");
            setTimeout(() => {
              this.$bvModal.show("toast-confirmation-modal");
              this.toastConfirmation = {
                title: this.$t("MY_PROFILE.CHNAGE_EMAIL_SUCCESSFULLY"),
                message: "",
                subMessage: "",
                imageName: "success",
                showCancelButton: false,
                showConfirmButton: false,
                showCloseButton: true,
                isConfirmationLoading: false,
              };
            }, 220);
            this.$bvModal.hide("change-email-modal");
          }
        })
        .finally(() => {
          this.emailLoading = false;
        });
    },

    // account activity

    openDisabledAccountModal() {
      this.$bvModal.show("confirmation-modal");
    },

    handleRemovePhone() {
      const body = {
        id: this.user.id,
        phone: null,
      };

      this.toastConfirmation.isConfirmationLoading = true;

      this.ApiService(patchUserRequest(body))
        .then(() => {
          this.handleSetUser("");
          this.resetConfirmationModal();
          setTimeout(() => {
            this.$bvModal.show("toast-confirmation-modal");
            this.toastConfirmation = {
              title: this.$t("MY_PROFILE.REMOVE_PHONE_SUCCESSFULLY"),
              message: "",
              subMessage: "",
              imageName: "success",
              showCancelButton: false,
              showConfirmButton: false,
              showCloseButton: true,
              isConfirmationLoading: false,
            };
          }, 220);
        })
        .finally(() => {
          this.toastConfirmation.isConfirmationLoading = false;
        });
    },

    handleSetUser(message) {
      const params = {
        token: this.token,
      };
      this.SetUser(params);
      this.handleSetMessage(message);
    },
    handleSetMessage(message) {
      this.ShowToast({
        title: this.$t("GLOBAL_SUCCESS"),
        type: "success",
        message: message,
      });
    },
    handleCloseModal() {
      this.modalName = null;
      this.selectedUser = null;
      this.selectedOtptype = null;
    },

    // exchange
    openRemoveExhangeModal(exchange) {
      this.selectedExchange = exchange;
      this.$bvModal.show("toast-confirmation-modal");
      // in case leader has orders
      if (this.user.is_leader && this.openOrders.length) {
        this.toastConfirmation = {
          title: this.$t("LEADERS.CLOSE_YOUR_ORDERS"),
          message: this.$t("LEADERS.UNLINK_YOUR_EXCHANGE"),
          name: "removeExchange",
          subMessage: "",
          imageName: "warning",
          showCancelButton: false,
          showConfirmButton: false,
          showCloseButton: true,
          isConfirmationLoading: false,
        };
      }
      // in case follower
      else if (this.user.followedLeaders.data.length && !this.user.is_leader) {
        this.toastConfirmation = {
          title: this.$t("LEADERS.UNFOLLOW_LEADER_FIRST"),
          message: this.$t("LEADERS.CANNOT_UNLINK_EXCHANGE"),
          name: "removeExchange",
          subMessage: "",
          imageName: "warning",
          showCancelButton: false,
          showConfirmButton: false,
          showCloseButton: true,
          isConfirmationLoading: false,
        };
      } else {
        this.toastConfirmation = {
          title: this.$t("LEADERS.UNLINK_EXCHANGE"),
          message: this.$t("LEADERS.UNLINK_EXCHANGE_MESSAGE"),
          subMessage: this.$t("LEADERS.UNDO_ACTION"),
          name: "removeExchange",
          imageName: "danger",
          subMessageClass: "danger",
          showCancelButton: true,
          showConfirmButton: true,
          showCloseButton: false,
          isConfirmationLoading: false,
        };
      }
    },
    handleRemoveExchange() {
      const body = {
        id: this.selectedExchange.id,
      };

      this.toastConfirmation.isConfirmationLoading = true;
      this.ApiService(deleteExchangeRequest(body))
        .then(() => {
          this.handleSetUser("");
          this.resetConfirmationModal();
          setTimeout(() => {
            this.$bvModal.show("toast-confirmation-modal");
            this.toastConfirmation = {
              title: this.$t("LEADERS.EXCHANGE_UNLINKED"),
              message: "",
              subMessage: "",
              imageName: "success",
              showCancelButton: false,
              showConfirmButton: false,
              showCloseButton: true,
              isConfirmationLoading: false,
            };
          }, 220);
        })
        .finally(() => {
          this.toastConfirmation.isConfirmationLoading = false;
        });
    },

    // delete account
    handleOpenDeleteAccountModal() {
      this.$bvModal.show("toast-confirmation-modal");

      this.toastConfirmation = {
        title: this.$t("MY_PROFILE.DELETE_ACCOUNT"),
        message: this.$t("MY_PROFILE.ARE_YOU_DELETE_ACCOUNT"),
        subMessage: "",
        name: "deleteAccount",
        imageName: "danger",
        showCancelButton: true,
        showConfirmButton: true,
        showCloseButton: false,
        isConfirmationLoading: false,
      };
    },
    handleDeleteAccount() {
      this.toastConfirmation.isConfirmationLoading = true;

      this.ApiService(deleteAccountRequest(this.user.id))
        .then(({ data }) => {
          this.handleSetMessage(data.meta.custom.message);
          this.resetConfirmationModal();
          this.Logout();
        })
        .finally(() => {
          this.toastConfirmation.isConfirmationLoading = false;
        });
    },

    handleLoadOpenOrders() {
      const params = {
        leader_id: this.user.leader.data.id,
      };

      this.ApiService(getOpenOrdersRequest(params)).then(({ data }) => {
        this.openOrders = data.data;
      });
    },

    // leader account
    handleGetLeaderActivities() {
      this.ApiService(getLeaderActivitiesRequest()).then(({ data }) => {
        this.leaderActivities = data.data;
      });
    },
    handleActiveOrDeactive(status) {
      this.selectedStatus = status;
      this.$bvModal.show("toast-confirmation-modal");

      this.toastConfirmation = {
        title:
          status === "active"
            ? this.$t("LEADERS.ACTIVATE_ACCOUNT")
            : status === "inactive"
            ? this.$t("LEADERS.DEACTIVATE_ACCOUNT")
            : this.$t("LEADERS.CANCELED_REQUEST"),

        message:
          status === "active"
            ? this.$t("LEADERS.MESSAGE_ACTIVATE_ACCOUNT")
            : status === "inactive"
            ? this.$t("LEADERS.MESSAGE_DEACTIVATE_ACCOUNT")
            : this.$t("LEADERS.MESSAGE_CANCELED"),
        subMessage: "",
        name: "changeStatusLeader",
        imageName: status === "danger" ? "danger" : "",
        showCancelButton: true,
        showConfirmButton: true,
        showCloseButton: false,
        isConfirmationLoading: false,
        confirmColor: "primary",
      };
    },

    handleUpdateLeaderStatus() {
      const body = {
        id: this.user.leader.data.id,
        status: this.selectedStatus,
      };

      this.toastConfirmation.isConfirmationLoading = true;
      this.ApiService(patchLeaderRequest(body))
        .then(() => {
          const params = {
            token: this.token,
          };
          this.SetUser(params);

          this.toastConfirmation = {
            title:
              this.selectedStatus === "active"
                ? this.$t("LEADERS.ACTIVATE_SUCCESSFULLY")
                : this.selectedStatus === "inactive"
                ? this.$t("LEADERS.DEACTIVATE_SUCCESSFULLY")
                : this.$t("LEADERS.CANCELED_SUCCESSFULLY"),

            subMessage: "",
            imageName: "success",
            showCancelButton: false,
            showConfirmButton: false,
            showCloseButton: true,
            isConfirmationLoading: false,
          };
        })
        .finally(() => {
          this.toastConfirmation.isConfirmationLoading = false;
        });
    },
    handleUpdateLeaderSettings(formValue) {
      const body = {
        id: this.user.leader.data.id,
        following_fee: formValue.followingFee === "free" ? "free" : "paid",
        pay_type:
          formValue.followingFee !== "free"
            ? formValue.followingFee
            : undefined,

        tips_address: formValue.tipsAddress,
      };
      if (formValue.followingFee == "fixed") {
        body.pay_value = formValue.fixedValue;
      } else if (formValue.followingFee == "percentage") {
        body.pay_value = formValue.percentageValue;
      }

      this.leaderLoading = true;
      this.ApiService(patchLeaderRequest(body))
        .then(() => {
          const params = {
            token: this.token,
          };
          this.SetUser(params);
          this.$bvModal.hide("leader-settings-modal");
          this.$bvModal.show("toast-confirmation-modal");

          setTimeout(() => {
            this.toastConfirmation = {
              title: this.$t("LEADERS.LEADER_SETTINGS_UPDATED"),
              subMessage: "",
              imageName: "success",
              showCancelButton: false,
              showConfirmButton: false,
              showCloseButton: true,
              isConfirmationLoading: false,
            };
          }, 220);
        })
        .finally(() => {
          this.leaderLoading = false;
        });
    },

    // toast confirmation
    onConfirm() {
      if (this.toastConfirmation.name === "removePhone") {
        this.handleRemovePhone();
      } else if (this.toastConfirmation.name === "removeExchange") {
        this.handleRemoveExchange();
      } else if (this.toastConfirmation.name === "deleteAccount") {
        this.handleDeleteAccount();
      } else if (this.toastConfirmation.name === "changeStatusLeader") {
        this.handleUpdateLeaderStatus();
      }
    },

    resetConfirmationModal() {
      this.$bvModal.hide("toast-confirmation-modal");
      setTimeout(() => {
        this.toastConfirmation = {
          title: "",
          message: "",
          name: "",
          subMessage: "",
          imageName: "success",
          subMessageClass: "",
          showCancelButton: false,
          showConfirmButton: false,
          showCloseButton: true,
          isConfirmationLoading: false,
        };
      }, 200);
      this.selectedStatus = null;
    },
    onClose() {
      this.resetConfirmationModal();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index";
</style>
