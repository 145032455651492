<template>
  <div>
    <h4 class="title">
      {{ $t("MY_PROFILE.PERMANENTLY_ACCOUNT") }}
    </h4>
    <p>
      {{ $t("MY_PROFILE.MESSAGE_DELETE_ACCOUNT") }}
    </p>
    <div class="text-right">
      <Button color="danger" @click="handleDeleteAccount">
        {{ $t("MY_PROFILE.DELETE_ACCOUNT") }}
      </Button>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    handleDeleteAccount() {
      this.$emit("handleDeleteAccount");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index";
</style>
