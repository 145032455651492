<template>
  <div>
    <b-modal
      id="leader-settings-modal"
      hide-footer
      @hide="closeModal"
      centered
      size="lg"
      content-class="custom-modal-container"
    >
      <template v-slot:modal-header="{ close }">
        <b-icon class="close-modal-icon" icon="x" @click="close"></b-icon>
      </template>
      <div :class="$i18n.locale == 'en' ? '' : 'rtl'">
        <h6 class="modal-top-title">
          {{ $t("LEADERS.LEADER_SETTINGS") }}
        </h6>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
          <b-form @submit.prevent="handleSubmit(onSubmit)" a-tag="formLeaderSettings">
            <div class="leader-container">
              <h4 class="leader-title">
                {{ $t("LEADERS.CHANGE_SUBSCRIPTION") }}
              </h4>

              <div class="radio-group">
                <b-form-radio v-model="form.followingFee" name="some-radios" value="free">
                  <div>
                    <h4 class="radio-label">
                      {{ $t("LEADERS.FREE_SUBSCRIPTION") }}
                    </h4>

                    <p class="radio-description">
                      {{ $t("LEADERS.TRAD_STRATEGIES") }}
                    </p>
                  </div>
                </b-form-radio>
              </div>

              <div class="radio-group">
                <b-form-radio v-model="form.followingFee" name="some-radios" value="fixed">
                  <div>
                    <h4 class="radio-label">
                      {{ $t("LEADERS.FIXED_SUBSCRIPTION") }}
                    </h4>

                    <b-input-group class="sms-verfication">
                      <template #prepend>
                        <span class="input-group-text">
                          <b-icon
                            class="radio-icon"
                            icon="currency-dollar"
                            :class="{ active: form.followingFee === 'fixed' }"
                          ></b-icon>
                        </span>
                      </template>

                      <TextField
                        class="w-100"
                        v-model="form.fixedValue"
                        :disabled="form.followingFee !== 'fixed'"
                        :name="$t('LEADERS.VALUE')"
                        :placeholder="$t('LEADERS.TYPE_VALUE')"
                        :rules="
                          form.followingFee === 'fixed' ? 'required|max_value:20|min_value:1' : ''
                        "
                      />
                    </b-input-group>
                  </div>
                </b-form-radio>
              </div>
              <div class="radio-group">
                <b-form-radio v-model="form.followingFee" name="some-radios" value="percentage">
                  <div>
                    <h4 class="radio-label">
                      {{ $t("LEADERS.PARCENTAGE_SUBSCRIPTION") }}
                    </h4>
                    <div class="range-container">
                      <range-slider
                        class="w-100"
                        min="1"
                        max="15"
                        v-model="form.percentageValue"
                        no-popover
                        :calibration-count="14"
                        :disabled="form.followingFee !== 'percentage'"
                      >
                      </range-slider>
                      <span
                        class="slider-value"
                        :class="{
                          disabled: form.followingFee !== 'percentage',
                        }"
                      >
                        {{ form.percentageValue }} %
                      </span>
                    </div>
                  </div>
                </b-form-radio>
              </div>
              <div class="beaware">
                <img src="@/assets/images/info.svg" alt="" />
                <p>
                  {{ $t("LEADERS.BEAWARE") }}
                </p>
              </div>
            </div>

            <div class="leader-container">
              <h4 class="leader-title">
                {{ $t("LEADERS.CHANGE_TIP") }}
              </h4>
              <b-input-group class="sms-verfication">
                <template #prepend>
                  <span class="input-group-text">
                    <img src="@/assets/images/bitcoin.svg" alt="" />
                  </span>
                </template>

                <!-- rules="url" -->
                <TextField
                  class="w-100"
                  v-model="form.tipsAddress"
                  :placeholder="$t('LEADERS.PASTE_ADDRESS')"
                />
              </b-input-group>
            </div>

            <b-form-group class="d-flex justify-content-end modal-actions">
              <div class="d-inline-block mr-3">
                <Button
                  type="button"
                  color="light-dark"
                  customClass="text-uppercase "
                  @click="$bvModal.hide('leader-settings-modal')"
                >
                  {{ $t("GLOBAL_CANCEL") }}
                </Button>
              </div>
              <Button
                type="submit"
                color="primary"
                :loading="loading"
                customClass="text-uppercase w-134"
              >
                {{ $t("GLOBAL_SAVE") }}
              </Button>
            </b-form-group>
          </b-form>
        </ValidationObserver>
      </div>
    </b-modal>
  </div>
</template>
<script>
import RangeSlider from "v-range-slider";

export default {
  props: {
    leader: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    RangeSlider,
  },
  watch: {
    leader(val) {
      if (!val) return;
      this.form = {
        followingFee: this.leader.pay_type || "free",
        tipsAddress: this.leader.tips_address,
        percentageValue: this.leader.pay_type === "percentage" ? this.leader.pay_value : 1,
        fixedValue: this.leader.pay_type === "fixed" ? this.leader.pay_value : null,
      };
    },
  },

  data() {
    return {
      form: {
        followingFee: "free",
        tipsAddress: null,
        percentageValue: 1,
        fixedValue: null,
      },
    };
  },
  methods: {
    onSubmit() {
      this.$emit("handleUpdateLeaderSettings", this.form);
    },

    closeModal() {
      this.$emit("closeModal");
      setTimeout(() => {
        this.form = {
          followingFee: "free",
          tipsAddress: null,
          percentageValue: 1,
          fixedValue: null,
        };
      }, 200);
      this.$refs.observer.reset();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index";
@import "~v-range-slider/dist/v-range-slider.css";
</style>
