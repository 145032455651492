<template>
  <div>
    <b-modal
      id="change-password-modal"
      hide-footer
      @hide="closeModal"
      centered
      size="lg"
      content-class="custom-modal-container"
    >
      <template v-slot:modal-header="{ close }">
        <b-icon class="close-modal-icon" icon="x" @click="close"></b-icon>
      </template>
      <div :class="$i18n.locale == 'en' ? '' : 'rtl'">
        <h6 class="modal-top-title">
          {{ $t("MY_PROFILE.CHNAGE_PASSWORD") }}
        </h6>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
          <b-form @submit.prevent="handleSubmit(onSubmit)" a-tag="formChangePassword">
            <div class="row">
              <b-form-group class="col-lg-12">
                <TextField
                  v-model="form.currentPassword"
                  rules="required"
                  :type="newPasswordType"
                  :label="$t('LOGIN_CURRENT_PASSWORD')"
                  :name="$t('LOGIN_CURRENT_PASSWORD')"
                  :placeholder="$t('LOGIN_ENTER_CURRENT_PASSWORD')"
                  class="p-relative d-block"
                  ref="password"
                >
                  <b-icon
                    :icon="newPasswordIcon"
                    @click="hideShowPassword('new')"
                    class="icon-password"
                  />
                </TextField>
              </b-form-group>
              <b-form-group class="col-lg-12">
                <TextField
                  v-model="form.password"
                  rules="required|verify_password"
                  :type="passwordType"
                  :label="$t('LOGIN_NEW_PASSWORD')"
                  :name="$t('LOGIN_NEW_PASSWORD')"
                  :placeholder="$t('LOGIN_ENTER_NEW_PASSWORD')"
                  class="p-relative d-block"
                  ref="password"
                >
                  <b-icon :icon="passwordIcon" @click="hideShowPassword" class="icon-password" />
                </TextField>
              </b-form-group>
              <b-form-group class="col-lg-12">
                <TextField
                  v-model="form.confirmPassword"
                  :rules="`required|confirmed:${$t('LOGIN_NEW_PASSWORD')}`"
                  :type="confirmPasswordType"
                  :label="$t('LOGIN_CONFIRM_PASSWORD')"
                  :name="$t('LOGIN_CONFIRM_PASSWORD')"
                  :placeholder="$t('LOGIN_CONFIRM_PASSWORD')"
                  class="p-relative d-block"
                >
                  <b-icon
                    :icon="confirmPasswordIcon"
                    @click="hideShowPassword('confirm')"
                    class="icon-password"
                  />
                </TextField>
              </b-form-group>
            </div>
            <b-form-group class="d-flex justify-content-end">
              <button
                type="button"
                class="forgot-password"
                a-tag="routeForgotPassword"
                @click="handleOpenForgotPasswordModal"
              >
                {{ $t("LOGIN_FORGET_PASSWORD") }}
              </button>

              <Button
                type="submit"
                color="primary"
                customClass="text-uppercase w-134"
                :loading="loading"
              >
                {{ $t("GLOBAL_SUBMIT") }}
              </Button>
            </b-form-group>
          </b-form>
        </ValidationObserver>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { TogglePasswordMixins } from "@/mixins/TogglePasswordMixins";

export default {
  mixins: [TogglePasswordMixins],

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        currentPassword: null,
        password: null,
        confirmPassword: null,
      },
    };
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
      this.$refs.observer.reset();
      this.form = {
        currentPassword: null,
        password: null,
        confirmPassword: null,
      };
    },
    onSubmit() {
      this.$emit("handleChangePassword", this.form);
    },
    handleOpenForgotPasswordModal() {
      this.$emit("handleOpenForgotPasswordModal");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index";
</style>
