<template>
  <div class="refer-container" v-if="user">
    <div class="icon">
      <b-icon icon="person-plus"></b-icon>
    </div>
    <div class="refer">
      <h1>{{ $t("MY_PROFILE.REFER_FRIENDS") }}</h1>
      <h5>{{ $t("MY_PROFILE.EARN") }}</h5>
    </div>

    <div class="share-code">
      <div>
        <h5>{{ $t("MY_PROFILE.SHARE_CODE") }} 20%</h5>
        <div class="code">
          <span> {{ user.referral_code }} </span>
          <b-icon @click="copy" class="copy-icon" icon="union" :title="$t('GLOBAL_COPY')"></b-icon>
        </div>
      </div>
      <b-icon class="share-icon" icon="upload" @click="handleShare" :title="$t('GLOBAL_SHARE')">
      </b-icon>
      <navigator-share
        class="d-none"
        ref="navigatorShare"
        :url="user.referral_link"
        :text="`${user.referral_code}`"
      >
      </navigator-share>
    </div>
  </div>
</template>

<script>
import NavigatorShare from "vue-navigator-share";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    NavigatorShare,
  },

  computed: {
    ...mapGetters(["user"]),
    title() {
      return document.title;
    },
  },
  methods: {
    ...mapActions(["ShowToast"]),

    copy() {
      const cb = navigator.clipboard;
      this.ShowToast({
        title: this.$t("GLOBAL_SUCCESS"),
        type: "success",
        message: this.$t("MY_PROFILE.COPIED"),
      });
      return cb.writeText(this.user.referral_link);
    },
    handleShare() {
      this.$refs.navigatorShare.share();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index";
</style>
