<template>
  <div>
    <b-modal
      id="forgot-password-modal"
      hide-footer
      @hide="closeModal"
      centered
      size="lg"
      content-class="custom-modal-container"
    >
      <template v-slot:modal-header="{ close }">
        <b-icon class="close-modal-icon" icon="x" @click="close"></b-icon>
      </template>
      <div :class="$i18n.locale == 'en' ? '' : 'rtl'">
        <h6 class="modal-top-title">
          {{ $t("LOGIN_PASSWORD_RECOVERY") }}
        </h6>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
          <b-form @submit.prevent="handleSubmit(onSubmit)" a-tag="formForgotPassword">
            <b-form-group>
              <TextField
                v-model="form.email"
                rules="required|email"
                :label="$t('LOGIN_ENTER_YOUR_EMAIL')"
                :name="$t('LOGIN_ENTER_YOUR_EMAIL')"
              />
            </b-form-group>
            <b-form-group class="d-flex justify-content-end">
              <Button
                type="submit"
                color="primary"
                customClass="text-uppercase w-134"
                :loading="loading"
              >
                {{ $t("GLOBAL_SUBMIT") }}
              </Button>
            </b-form-group>
          </b-form>
        </ValidationObserver>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        email: null,
        reseturl: process.env.VUE_APP_ROOT + "choose-password",
      },
    };
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
      this.$refs.observer.reset();
      this.form = {
        email: null,
      };
    },
    onSubmit() {
      this.$emit("handleForgotPassword", this.form);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index";
</style>
