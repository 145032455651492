<template>
  <div>
    <b-modal
      id="change-email-modal"
      hide-footer
      @hide="closeModal"
      centered
      size="lg"
      content-class="custom-modal-container"
    >
      <template v-slot:modal-header="{ close }">
        <b-icon class="close-modal-icon" icon="x" @click="close"></b-icon>
      </template>
      <div :class="$i18n.locale == 'en' ? '' : 'rtl'">
        <h6 class="modal-top-title">
          {{ $t("MY_PROFILE.CHNAGE_EMAIL") }}
        </h6>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
          <b-form @submit.prevent="handleSubmit(onSubmit)" a-tag="formChangeEmail">
            <b-form-group>
              <TextField
                v-model="form.email"
                rules="required|email"
                :label="$t('LOGIN_EMAIL')"
                :name="$t('LOGIN_EMAIL')"
                :placeholder="$t('MY_PROFILE.ENTER_EMAIL')"
              />
            </b-form-group>
            <b-form-group v-if="otptype === 'email'">
              <label for="">
                {{ $t("MY_PROFILE.SMS_VERFICATION") }}
              </label>
              <b-input-group :prepend="$t('MY_PROFILE.SEND_CODE')" class="sms-verfication">
                <TextField
                  class="w-100"
                  v-model="form.otp"
                  rules="required|numeric"
                  :name="$t('MY_PROFILE.SMS_VERFICATION')"
                  :placeholder="$t('MY_PROFILE.ENTER_SMS_VERFICATION')"
                />
              </b-input-group>
            </b-form-group>

            <b-form-group class="d-flex justify-content-end">
              <div class="d-inline-block mr-3">
                <Button
                  type="button"
                  color="light-dark"
                  customClass="text-uppercase"
                  @click="$bvModal.hide('change-email-modal')"
                >
                  {{ $t("GLOBAL_CANCEL") }}
                </Button>
              </div>
              <Button
                type="submit"
                color="primary"
                :loading="loading"
                customClass="text-uppercase w-134"
              >
                {{ $t("GLOBAL_SUBMIT") }}
              </Button>
            </b-form-group>
          </b-form>
        </ValidationObserver>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    selectedUser: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
    otptype: {
      type: String,
      default: "",
    },
  },

  watch: {
    selectedUser: {
      immediate: true,
      handler(val) {
        if (!val) return;
        this.form = {
          email: val.email,
        };
      },
    },
  },

  data() {
    return {
      form: {
        email: null,
        otp: null,
      },
    };
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
      this.$refs.observer.reset();
      this.form = {
        email: null,
        otp: null,
      };
    },
    onSubmit() {
      this.$emit("handleChangeEmail", this.form);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index";
</style>
