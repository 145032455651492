var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"id":"add-phone-modal","hide-footer":"","centered":"","size":"lg","content-class":"custom-modal-container"},on:{"hide":_vm.closeModal},scopedSlots:_vm._u([{key:"modal-header",fn:function(ref){
var close = ref.close;
return [_c('b-icon',{staticClass:"close-modal-icon",attrs:{"icon":"x"},on:{"click":close}})]}}])},[_c('div',{class:_vm.$i18n.locale == 'en' ? '' : 'rtl'},[_c('h6',{staticClass:"modal-top-title"},[_vm._v(" "+_vm._s(_vm.selectedUser && _vm.selectedUser.phone ? _vm.$t("MY_PROFILE.CHNAGE_PHONE") : _vm.$t("MY_PROFILE.ADD_PHONE"))+" ")]),_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{attrs:{"a-tag":"formAddPhone"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('div',{staticClass:"row"},[_c('b-form-group',{staticClass:"col-lg-12"},[_c('TextField',{attrs:{"rules":"required|numeric","label":_vm.selectedUser && _vm.selectedUser.phone
                    ? _vm.$t('MY_PROFILE.NEW_PHONE')
                    : _vm.$t('MY_PROFILE.PHONE_NUMBER'),"name":_vm.$t('GLOBAL_PHONE'),"placeholder":_vm.selectedUser && _vm.selectedUser.phone
                    ? _vm.$t('MY_PROFILE.NEW_PHONE')
                    : _vm.$t('MY_PROFILE.PHONE_NUMBER')},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}})],1),(_vm.otptype === 'phone')?_c('b-form-group',{staticClass:"col-lg-12"},[_c('label',{attrs:{"for":""}},[_vm._v(" "+_vm._s(_vm.$t("MY_PROFILE.SMS_VERFICATION"))+" ")]),_c('b-input-group',{staticClass:"sms-verfication",attrs:{"prepend":_vm.$t('MY_PROFILE.SEND_CODE')}},[_c('TextField',{staticClass:"w-100",attrs:{"rules":"required|numeric","name":_vm.$t('MY_PROFILE.SMS_VERFICATION'),"placeholder":_vm.$t('MY_PROFILE.ENTER_SMS_VERFICATION')},model:{value:(_vm.form.otp),callback:function ($$v) {_vm.$set(_vm.form, "otp", $$v)},expression:"form.otp"}})],1)],1):_vm._e()],1),_c('b-form-group',{staticClass:"d-flex justify-content-end"},[_c('div',{staticClass:"d-inline-block mr-3"},[_c('Button',{attrs:{"type":"button","color":"light-dark","customClass":"text-uppercase"},on:{"click":function($event){return _vm.$bvModal.hide('add-phone-modal')}}},[_vm._v(" "+_vm._s(_vm.$t("GLOBAL_CANCEL"))+" ")])],1),_c('Button',{attrs:{"type":"submit","color":"primary","loading":_vm.loading,"customClass":"text-uppercase w-134"}},[_vm._v(" "+_vm._s(_vm.$t("GLOBAL_SUBMIT"))+" ")])],1)],1)]}}])})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }