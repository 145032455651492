<template>
  <div>
    <b-modal
      :id="id"
      hide-footer
      @hide="closeModal"
      centered
      size="lg"
      content-class="custom-modal-container"
    >
      <template v-slot:modal-header="{ close }">
        <b-icon class="close-modal-icon" icon="x" @click="close"></b-icon>
      </template>
      <div :class="$i18n.locale == 'en' ? '' : 'rtl'">
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
          <h6 class="modal-top-title">
            {{ $t(modalTitle) }}
          </h6>
          <b-form @submit.prevent="handleSubmit(onSubmit)" a-tag="formAddUsername">
            <div class="row">
              <b-form-group class="col-lg-12">
                <TextField
                  v-model="form.firstName"
                  rules="required"
                  :label="$t('LOGIN_FIRST_NAME')"
                  :name="$t('LOGIN_FIRST_NAME')"
                  :placeholder="$t('LOGIN_ENTER_FIRST_NAME')"
                />
              </b-form-group>
              <b-form-group class="col-lg-12">
                <TextField
                  v-model="form.lastName"
                  rules="required"
                  :label="$t('LOGIN_LAST_NAME')"
                  :name="$t('LOGIN_LAST_NAME')"
                  :placeholder="$t('LOGIN_ENTER_LAST_NAME')"
                />
              </b-form-group>
            </div>
            <b-form-group class="d-flex justify-content-end">
              <div class="d-inline-block mr-3">
                <Button
                  type="button"
                  color="light-dark"
                  customClass="text-uppercase"
                  @click="$bvModal.hide(id)"
                >
                  {{ $t("GLOBAL_CANCEL") }}
                </Button>
              </div>
              <Button
                type="submit"
                color="primary"
                :loading="loading"
                customClass="text-uppercase w-134"
              >
                {{ $t(confirmTitle) }}
              </Button>
            </b-form-group>
          </b-form>
        </ValidationObserver>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    selectedUser: {
      type: Object,
      default: () => {},
    },
    id: {
      type: String,
      default: "complete-profile-modal",
    },
    modalTitle: {
      type: String,
      default: "MY_PROFILE.CHNAGE_USER_NAME",
    },
    confirmTitle: {
      type: String,
      default: "GLOBAL_SUBMIT",
    },
  },

  watch: {
    selectedUser: {
      immediate: true,
      handler(val) {
        if (!val) return;
        this.form = {
          firstName: val.first_name,
          lastName: val.last_name,
        };
      },
    },
  },

  data() {
    return {
      form: {
        firstName: null,
        lastName: null,
      },
    };
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
      this.$refs.observer.reset();
      this.form = {
        firstName: null,
        lastName: null,
      };
    },
    onSubmit() {
      this.$emit("handleChange", this.form);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index";
</style>
